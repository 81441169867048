export const Type = {
  FETCH_IN_RUNNING_FIXTURES: "gamecenter/FETCH_IN_RUNNING_FIXTURES",
  FETCH_IN_RUNNING_FIXTURES_SUCCEEDED: "gamecenter/FETCH_IN_RUNNING_FIXTURES_SUCCEEDED",
  FETCH_IN_RUNNING_FIXTURES_FAILED: "gamecenter/FETCH_IN_RUNNING_FIXTURES_FAILED",
  FETCH_MATRIX: "gamecenter/FETCH_MATRIX",
  FETCH_MATRIX_SUCCEEDED: "gamecenter/FETCH_MATRIX_SUCCEEDED",
  FETCH_MATRIX_FAILED: "gamecenter/FETCH_MATRIX_FAILED",
  SHOW_MATRIX: "gamecenter/SHOW_MATRIX",
  HIDE_MATRIX: "gamecenter/HIDE_MATRIX",
  START_BACKGROUND_REFRESH: "gamecenter/START_BACKGROUND_REFRESH",
  STOP_BACKGROUND_REFRESH: "gamecenter/STOP_BACKGROUND_REFRESH",
  POST_REMOVE_FIXTURE: "gamecenter/POST_REMOVE_FIXTURE",
  POST_UPDATE_FIXTURE: "gamecenter/POST_UPDATE_FIXTURE",
  FETCH_BROWSE: "gamecenter/FETCH_BROWSE",
  FETCH_BROWSE_SUCCEEDED: "gamecenter/FETCH_BROWSE_SUCCEEDED",
  FETCH_BROWSE_FAILED: "gamecenter/FETCH_BROWSE_FAILED",
  TOGGLE_ONLY_WITH_ORDERS: "gamecenter/TOGGLE_ONLY_WITH_ORDERS",
  FETCH_WIDGET_DATA: "gamecenter/FETCH_WIDGET_DATA",
  FETCH_WIDGET_DATA_SUCCEEDED: "gamecenter/FETCH_WIDGET_DATA_SUCCEEDED",
  FETCH_WIDGET_DATA_FAILED: "gamecenter/FETCH_WIDGET_DATA_FAILED",
  SET_SELECTED_ACCOUNT_NAMES: "gamecenter/SET_SELECTED_ACCOUNT_NAMES",
  SET_MATRIX_TIMESTAMP: "gamecenter/SET_MATRIX_TIMESTAMP",
}

export function startBackgroundRefresh() {
  return { type: Type.START_BACKGROUND_REFRESH }
}

export function stopBackgroundRefresh() {
  return { type: Type.STOP_BACKGROUND_REFRESH }
}

export function fetchInRunningFixtures() {
  return { type: Type.FETCH_IN_RUNNING_FIXTURES }
}

export function fetchInRunningFixturesSucceeded(response) {
  return { type: Type.FETCH_IN_RUNNING_FIXTURES_SUCCEEDED, response }
}

export function fetchInRunningFixturesFailed(error) {
  return { type: Type.FETCH_IN_RUNNING_FIXTURES_FAILED, error }
}

export function fetchMatrix(fixtureId, asOfTimestamp) {
  return { type: Type.FETCH_MATRIX, fixtureId, asOfTimestamp }
}

export function fetchMatrixSucceeded(fixtureId, response) {
  return { type: Type.FETCH_MATRIX_SUCCEEDED, fixtureId, response }
}

export function fetchMatrixFailed(fixtureId, error) {
  return { type: Type.FETCH_MATRIX_FAILED, fixtureId, error }
}

export function showMatrix(fixtureId, fromBrowse = false) {
  return { type: Type.SHOW_MATRIX, fixtureId, fromBrowse }
}

export function hideMatrix() {
  return { type: Type.HIDE_MATRIX }
}

export function postRemoveFixture(fixtureId) {
  return { type: Type.POST_REMOVE_FIXTURE, fixtureId }
}

export function postUpdateFixture(fixtureIds) {
  return { type: Type.POST_UPDATE_FIXTURE, fixtureIds }
}

export function fetchBrowse(from, to, sport) {
  return { type: Type.FETCH_BROWSE, from, to, sport }
}

export function fetchBrowseSucceeded(response) {
  return { type: Type.FETCH_BROWSE_SUCCEEDED, response }
}

export function fetchBrowseFailed(error) {
  return { type: Type.FETCH_BROWSE_FAILED, error }
}

export function toggleOnlyWithOrders() {
  return { type: Type.TOGGLE_ONLY_WITH_ORDERS }
}

export function setSelectedAccountNames(accountNames) {
  return { type: Type.SET_SELECTED_ACCOUNT_NAMES, accountNames }
}

export function setMatrixTimestamp(timestamp) {
  return { type: Type.SET_MATRIX_TIMESTAMP, timestamp }
}

export function fetchWidgetDataSucceeded(fixtureId, response) {
  return { type: Type.FETCH_WIDGET_DATA_SUCCEEDED, fixtureId, response }
}

export function fetchWidgetDataFailed(error) {
  return { type: Type.FETCH_WIDGET_DATA_FAILED, error }
}